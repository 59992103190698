<template>
  <div class="header-slider">
    <div class="slider-container" v-if="!isMobile">
      <VueSlickCarousel :arrows="false" :autoplay="true" :dots="false">
        <div><img src="../../assets/slider-01.jpg" /></div>
        <div><img src="../../assets/slider-02.jpg" /></div>
        <div><img src="../../assets/slider-03.jpg" /></div>
        <div><img src="../../assets/slider-04.jpg" /></div>
      </VueSlickCarousel>
    </div>
    <div class="rider">
      <img src="../../assets/all-the-riders.png" />
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  name: "HeaderSlider",
  components: { VueSlickCarousel },
  computed: {
    isMobile() {
      return window.innerWidth < 768;
    },
  },
};
</script>

<style scoped lang="scss">
.header-slider {
  position: relative;
  display: flex;
  justify-content: space-between;
  .slider-container {
    width: 100%;
    max-width: 50%;
  }
  .rider {
    flex-grow: 1;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    img {
      height: 290px;
      @media screen and (max-width: 767px) {
        height: 140px;
      }
    }
  }

  .slick-slider {
    width: 630px;
    margin: 0 auto;

    img {
      width: 630px !important;
      height: 295px;
    }
  }

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
}
</style>
