import Vue from "vue";
import VueRouter from "vue-router";
import AboutTheTour from "../pages/AboutTheTour.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: AboutTheTour,
  },
  {
    path: "/swami-vivekananda",
    name: "SwamiVivekananda",
    component: () => import("../pages/SwamiVivekananda.vue"),
  },
  {
    path: "/about-the-tour",
    name: "AboutTheTour",
    component: () => import("../pages/AboutTheTour.vue"),
  },
  {
    path: "/tour-route",
    name: "TourRoute",
    component: () => import("../pages/TourRoute.vue"),
  },
  {
    path: "/tour-map",
    name: "TourMap",
    component: () => import("../pages/TourMap.vue"),
  },
  {
    path: "/photo-gallery",
    name: "PhotoGallery",
    component: () => import("../pages/PhotoGallery.vue"),
  },
  {
    path: "/photo-gallery/:slug",
    name: "PhotoGalleryDetails",
    component: () => import("../pages/PhotoGalleryDetail.vue"),
  },
  {
    path: "/in-news",
    name: "InNews",
    component: () => import("../pages/InNews.vue"),
  },
  {
    path: "/contact-us",
    name: "ContactUs",
    component: () => import("../pages/ContactUs.vue"),
  },
  {
    path: "*",
    name: "PageNotFound",
    component: () => import("../pages/PageNotFound.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    //Default Scroll to top
    return { x: 0, y: 0 };
  },
});

export default router;
