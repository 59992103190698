<template>
  <div id="app">
    <Header />
    <HeaderSlider />
    <Quote />
    <div class="container">
      <div class="main-content">
        <router-view />
      </div>
    </div>
    <SiteInfo />
    <Footer />
  </div>
</template>
<script>
import Header from "./components/shared/Header";
import HeaderSlider from "./components/shared/HeaderSlider";
import Quote from "./components/shared/Quote";
import SiteInfo from "./components/shared/SiteInfo";
import Footer from "./components/shared/Footer";
export default {
  name: "App",
  components: {
    Header,
    HeaderSlider,
    Quote,
    SiteInfo,
    Footer,
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss">
*,
:after,
:before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  user-select: none;

  outline: none;
}
body {
  margin: 0;
  padding: 0;
  background: url("./assets/page-body-bg.jpeg");
  background-attachment: fixed;
  font-size: 16px;
  color: #000;
  padding-top: 50px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
a {
  transition: color, background-color 0.3s;
}
img {
  max-width: 100%;
}
.container {
  max-width: 1110px;
  margin: 0 auto;
}
.back-link {
  border-radius: 4px;
  color: #02405f;
}
.main-content {
  background: #fff;
  padding: 30px;
  border-radius: 6px;
  margin: 20px auto;
  @media screen and (max-width: 767px) {
    padding: 16px;
    border-radius: 0;
  }

  h1,
  h2 {
    line-height: 1.5;
    text-align: center;
    color: #02405f;
    @media screen and (max-width: 767px) {
      margin-top: 0;
    }

    small {
      display: block;
      font-size: 1rem;
      color: #000;
    }
  }
  @media screen and (max-width: 767px) {
    h1 {
      font-size: 24px;
    }
    h2 {
      font-size: 20px;
    }
  }
}

.list li {
  list-style: none;
  line-height: 25px;
}

.list li:before {
  content: url("./assets/post-bullets.png");
  margin: 0 6px 2px 0;
}
</style>
