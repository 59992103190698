<template>
  <footer>
    <div class="social-buttons">
      <a href="https://www.facebook.com/bharatparikrama2013" target="_blank">
        <img src="../../assets//icon-facebook.svg" />
      </a>
      <a href="https://twitter.com/ParikramaBharat" target="_blank">
        <img src="../../assets//icon-twitter.svg" />
      </a>
    </div>
    Copyright © 2013-{{ currentYear }} @ Bharat Parikrama Team | Rights
    Reserved.
  </footer>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>
<style scoped lang="scss">
footer {
  background: #ccc;
  padding: 10px;
  text-align: center;
  font-size: 14px;

  .social-buttons img {
    width: 30px;
    margin: 10px;
  }
}
</style>
