<template>
  <div class="quote">
    "उठो! जागो!! रुको नहीं, जब तक लक्ष्य प्राप्त ना हो|"
    <span>-स्वामी विवेकानंद</span>
  </div>
</template>

<script>
export default {
  name: "Quote",
  data() {
    return {
      currentYear: new Date(),
    };
  },
};
</script>
<style scoped lang="scss">
.quote {
  text-align: center;
  font-size: 28px;
  background: #fff;
  padding: 20px;
  margin: 0 auto 15px auto;
  box-shadow: 0 0 10px #ccc;
  font-weight: bold;
  span {
    font-size: 16px;
    display: block;
    margin-top: 5px;
    font-weight: normal;
  }
  @media screen and (max-width: 767px) {
    font-size: 16px;
    padding: 10px;
    span {
      font-size: 14px;
    }
  }
}
</style>
