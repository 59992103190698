<template>
  <div class="site-info">
    A three-member biker team circumnavigates India on motorcycles in around 71
    days, covering 28 states-5 UT’s &amp; around 21,000Kms.
  </div>
</template>

<script>
export default {
  name: "SiteInfo",
  data() {
    return {
      currentYear: new Date(),
    };
  },
};
</script>
<style scoped lang="scss">
.site-info {
  text-align: center;
  background: #fff;
  font-size: 24px;
  padding: 24px;
  box-shadow: 0 0 10px #ccc;
  margin: 16px auto;
  @media screen and (max-width: 767px) {
    margin: 8px auto;
    font-size: 16px;
    padding: 18px;
  }
}
</style>
