<template>
  <header>
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      v-if="isMobile"
      class="mobile-menu-link"
      :class="{ opened: isMobile && mobileMenuActive }"
      @click="mobileMenuActive = !mobileMenuActive"
    >
      <path
        class="line line1"
        d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
      />
      <path class="line line2" d="M 20,50 H 80" />
      <path
        class="line line3"
        d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
      />
    </svg>
    <router-link class="home-link" to="/">
      <img
        @click="mobileMenuActive = false"
        src="../../assets/logo-bharat-parikrama.svg"
      />
    </router-link>

    <nav :class="{ 'mobile-menu-active': isMobile && mobileMenuActive }">
      <ul>
        <li @click="mobileMenuActive = false">
          <router-link to="/">मुख्य पृष्ठ</router-link>
        </li>
        <li @click="mobileMenuActive = false">
          <router-link to="/swami-vivekananda/">स्वामी विवेकानंद</router-link>
        </li>
        <li @click="mobileMenuActive = false">
          <router-link to="/tour-route/">परिक्रमा मार्ग</router-link>
        </li>
        <li @click="mobileMenuActive = false">
          <router-link to="/tour-map/">परिक्रमा मानचित्र</router-link>
        </li>
        <li @click="mobileMenuActive = false">
          <router-link to="/photo-gallery/">फोटो गैलरी</router-link>
        </li>
        <li @click="mobileMenuActive = false">
          <router-link to="/in-news/">अखबारों में</router-link>
        </li>
        <li @click="mobileMenuActive = false">
          <router-link to="/contact-us/">संपर्क सूत्र</router-link>
        </li>
      </ul>
    </nav>

    <img class="be-make-image" src="../../assets/sv-150-logo.png" />
  </header>
</template>

<script>
export default {
  name: "Header",
  data() {
    return {
      mobileMenuActive: false,
    };
  },
  computed: {
    isMobile() {
      return window.innerWidth < 768;
    },
  },
};
</script>
<style scoped lang="scss">
header {
  padding: 4px 1rem;
  top: 0;
  left: 0;
  position: fixed;
  background: #fff;
  width: 100%;
  z-index: 999;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 0 20px #666;
  height: 50px;
  @media screen and (max-width: 767px) {
    padding-left: 0;
  }
}
header * {
  transition: 0.3s;
}
img {
  height: 60px;
}
.home-link {
  display: flex;
  align-items: center;
  @media screen and (max-width: 767px) {
    img {
      height: 50px;
    }
  }
}
.be-make-image {
  height: 40px;
}
.mobile-menu-link {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  padding: 0;
  width: 40px;
}
.line {
  fill: none;
  stroke: black;
  stroke-width: 6;
  transition: stroke-dasharray 600ms cubic-bezier(0.4, 0, 0.2, 1),
    stroke-dashoffset 600ms cubic-bezier(0.4, 0, 0.2, 1);
}
.line1 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}
.line2 {
  stroke-dasharray: 60 60;
  stroke-width: 6;
}
.line3 {
  stroke-dasharray: 60 207;
  stroke-width: 6;
}
.opened .line1 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}
.opened .line2 {
  stroke-dasharray: 1 60;
  stroke-dashoffset: -30;
  stroke-width: 6;
}
.opened .line3 {
  stroke-dasharray: 90 207;
  stroke-dashoffset: -134;
  stroke-width: 6;
}
nav {
  padding: 0;
  text-align: center;

  ul {
    li {
      display: inline-block;
      list-style: none;
      margin: 0 5px;
      a {
        color: #02405f;
        display: inline-block;
        padding: 10px;
        text-decoration: none;
        border-radius: 8px;

        a &:hover,
        &.router-link-exact-active {
          background: #02405f;
          color: #fff;
        }
      }
    }
  }
  @media screen and (max-width: 767px) {
    position: fixed;
    left: -100%;
    top: 50px;
    height: calc(100% - 50px);
    width: 100%;

    background: #02405f;
    &.mobile-menu-active {
      left: 0;
    }
    ul {
      margin: 0;
      padding: 0;
      li {
        display: block;
        margin: 0;
        a {
          color: #fff;
          text-align: left;
          display: block;
          padding: 1rem;
          border-radius: 0;
          border-bottom: 1px solid #fff;
          &.router-link-exact-active {
            color: #02405f;
            background: #fff;
          }
        }
      }
    }
  }
}
</style>
